
@media (min-width: 950px) {
    .digiYOSection {
        background-image: url('../assets/images/Banner.svg'); /* Default for desktop */
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-size: 100%;
        background-color: transparent;
    }

    .mobile-image {
        display: none;
    }
}

@media (max-width: 950px) {
    .mobile-image {
        display: block;
        width: 100%;
        text-align: center;
    }

    .mobile-image img {
        width: 100%;
        height: auto;
    }
}


@font-face {
  font-family: 'FontAwesome';
  src: url('./assets/fonts/fontawesome-webfont.woff2') format('woff2'),
       url('./assets/fonts/fontawesome-webfont.woff') format('woff');
  /* Add additional font formats if needed */
  font-weight: bold;
  font-style: bold;
}


body {
  margin: 0;
  font-family: 'FontAwesome', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 300px !important;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

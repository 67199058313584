.feature-dropdown {
    display: none; /* Initially hide the dropdown */
    flex-direction: row;
    padding: 10px;
    position: absolute;
    background-color: #E1F2FF;
    z-index: 1000;
  }
  
  .feature-item {
    border: 1px solid black;
    border-radius: 5px;
    background-color: #E1F2FF;
    padding: 15px;
    margin: 5px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 170px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }
  
  .feature-item:hover {
    background-color: #1877F2;
    ;
    color: white !important;
  }
  
  .feature-icon {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .feature-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .feature-description {
    font-size: 12px;
    word-wrap: break-word;
    white-space: normal;
  }
  
  .nav-item.dropdown.show .feature-dropdown {
    display: flex; /* Show dropdown when parent has class 'show' */
  }
  .nav-item:hover {
    cursor: pointer;
  }
  